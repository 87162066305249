import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h3>this url does not exist</h3>
        </div>
    );
};

export default NotFoundPage;