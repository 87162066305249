import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

// TDB api imports
import { getCategories } from '../api/CategoryApi';
import { postBet } from '../api/BetsApi';

// Breadcrumbs
import Breadcrumb from '../Breadcrumb';
import { MdHome, MdList, MdNoteAdd } from 'react-icons/md'
import { Link } from 'react-router-dom';

// Form Elements and Validation
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { DisplayFormikState } from "./helper";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Bets: MdList,
    'New Bet': MdNoteAdd
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/bets', label: 'Bets' },
    { to: '/new', label: 'New Bet' }
  ]
}

class NewBet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tdbmember: ''
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    this.updateCategories();
    this.updateMemberInfo();
  }

  updateCategories() {
    getCategories()
      .then((data) => {
        this.setState({
          categories: data
        })
      })
      .catch((e) => {
        console.log("getCategories failed");
      });
  };

  async updateMemberInfo() {
    Auth.currentUserInfo({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(
      user => {
        this.setState({
          tdbmember: user.username
        })
      })
      .catch(
        err => console.log(err)
      );
  }

  handleSelectChange = selectedOption => {
    this.setState({
      category: selectedOption.value
    });
  }

  render() {
    let categoryOptions = this.state.categories.map(function (category) {
      return { value: category.shortname, label: category.longname };
    });
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col>
              <Formik
                initialValues={{ betdate: '', category: '', bettext: '' }}
                onSubmit={async values => {
                  var shortDate = new Date(values.betdate);
                  var dateStamp = shortDate.toLocaleDateString();
                  postBet({
                    betdate: dateStamp,
                    category: values.category.value,
                    bettext: values.bettext,
                    tdbmember: this.state.tdbmember
                  }).then((result) => {
                    values.betdate = '';
                    values.category = null;
                    values.bettext = '';
                    this.props.history.push('/bets');
                  }).catch((e) => {
                    console.log("post Bet failed", e);
                  });
                }}
                validationSchema={Yup.object().shape({
                  category: Yup.string()
                    .required('Required'),
                  bettext: Yup.string()
                    .max(500, 'Must be 500 characters or less')
                    .min(10, 'Must be 10 or more characters')
                    .required('Required')
                })}
              >
                {({ values,
                  handleChange,
                  handleBlur,
                  handleReset,
                  dirty,
                  props,
                  touched,
                  errors,
                  setFieldValue,
                  isSubmitting }) => (
                    <Form>
                      <label htmlFor="betdate" style={{ display: "block" }}>
                        Bet Date</label>
                      <DatePicker
                        selected={values.betdate}
                        name="betdate"
                        placeholderText="Click to choose bet date"
                        onChange={date => setFieldValue('betdate', date)}
                      />
                      <ErrorMessage component="div" name="betdate" className="input-feedback" />
                      <label htmlFor="category" style={{ display: "block" }}>
                        Bet Category</label>
                      <Select
                        placeholder="Enter Category"
                        value={values.category}
                        onChange={option => setFieldValue('category', option)}
                        options={categoryOptions} />
                      <ErrorMessage component="div" name="category" className="input-feedback" />
                      <label htmlFor="bettext" style={{ display: "block" }}>
                        Bet Text</label>
                      <Field
                        name="bettext"
                        placeholder="Enter your bet text"
                        type="text"
                        value={values.bettext}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.bettext && touched.bettext
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      <ErrorMessage component="div" name="bettext" className="input-feedback" />
                      <br />
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}>Reset</Button>
                      <Button type="submit" disabled={isSubmitting}>Submit</Button>
                      <DisplayFormikState {...props} />
                    </Form>)}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default NewBet;