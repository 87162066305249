import { API } from 'aws-amplify';

const API_NAME = 'TwoDollarBetApi';
const PATH = '/members'

export const postMember = async (member) => {
  return await API.post(API_NAME, PATH, {
    body: {
      firstname: member.firstname,
      lastname: member.lastname
    }
  });
}

export const updateMember = async (member) => {
  console.log("start MemberAPI updateMember");
  console.log(member);
  return await API.post(API_NAME, PATH + '/object/' + member.id, {
    body: {
      firstname: member.firstname,
      lastname: member.lastname,
      smsreceiveall: member.smsreceiveall,
      smsreceivemember: member.smsreceivemember
    }
  });
}

export const getAllMembers = async () => {
  return await API.get(API_NAME, PATH);
}

export const getMember = async (id) => {
  console.log("MemberAPI: getMember ", id)
  return await API.get(API_NAME, PATH + '/object/' + id);
}

export const removeMember = async (id) => {
  return await API.del(API_NAME, PATH + '/object/' + id);
}