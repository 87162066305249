import React, { Component, Fragment } from 'react'
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import '../App.css'
import "../assets/css/helper.css"
//import Amplify, { AUTH } from 'aws-amplify'
import Amplify from 'aws-amplify'
import awsconfig from '../aws-exports'

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome, MdList, MdEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'

// Shared Components
import BetDetail from './betDetail'

// Add Bettor Form Items
import Select from 'react-select' 

import { getBet, addWager, completeBet } from '../api/BetsApi'
import { getAllMembers } from '../api/MemberApi'
import { Button, Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { DisplayFormikState } from "./helper"
import * as Yup from "yup"

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Bets: MdList,
    'Update Bet': MdEdit
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/bets', label: 'Bets' },
    { to: '/betupdate', label: 'Update Bet' }
  ]
}

class BetUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state[0].id,
      bet: [],
      members: [],
      wagers: [],
      tdbmember: ''
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.updateBet();
    this.updateMembers();
    this.updateMemberInfo();
  }

  updateBet() {
    getBet(this.state.id)
      .then((data) => {
        this.setState({
          bet: data,
          wagers: data.wagers
        })
      })
      .catch((e) => {
        console.log("getBet failed");
      });
  }

  updateMembers() {
    getAllMembers()
      .then((data) => {
        this.setState({
          members: data
        })
      })
      .catch((e) => {
        console.log("getMember failed");
      });
  }

  async updateMemberInfo() {
    Auth.currentUserInfo({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(
      user => {
        this.setState({
          tdbmember: user.username
        })
      })
      .catch(
        err => console.log(err)
      );
  }

  handleSelectChange = (selectedOption, item) => {
    this.setState({
      [item.name]: selectedOption.value
    });
  }

  render(props) {
    let memberOptions = this.state.members.map(function (member) {
      return { value: member.email, label: member.id };
    });
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col>
              <BetDetail bet={ this.state.bet } wagers={ this.state.wagers } /> 
            </Col>
            <Col>
              <Tabs id="aaa" defaultActiveKey="add">
                <Tab eventKey="add" title="Add Bettors">
                  <Formik
                    initialValues={{ pro: '', con: '' }}
                    onSubmit={async values => {
                      addWager({
                        id: this.state.id,
                        pro: values.pro.value,
                        con: values.con.value,
                        tdbmember: this.state.tdbmember
                      }).then((result) => {
                        this.updateBet();
                        values.pro = null;
                        values.con = null;
                      }).catch((e) => {
                        console.log("post bet failed");
                      });
                    }}
                    validationSchema={Yup.object().shape({
                      pro: Yup.string()
                        .required('Required'),
                      con: Yup.string()
                        .required('Required')
                    })}
                  >
                    {({ values,
                      handleChange,
                      handleBlur,
                      handleReset,
                      dirty,
                      props,
                      touched,
                      errors,
                      setFieldValue,
                      isSubmitting }) => (
                        <Form>
                          <label htmlFor="pro" style={{ display: "block" }}>
                            Pro</label>
                          <Select
                            placeholder="Enter Pro"
                            value={values.pro}
                            onChange={option => setFieldValue('pro', option)}
                            options={memberOptions} />
                          <ErrorMessage component="div" name="pro" className="input-feedback" />
                          <label htmlFor="con" style={{ display: "block" }}>
                            Con</label>
                          <Select
                            placeholder="Enter Con"
                            value={values.con}
                            onChange={option => setFieldValue('con', option)}
                            options={memberOptions} />
                          <ErrorMessage component="div" name="con" className="input-feedback" />
                          <br />
                          <Button
                            type="button"
                            className="outline"
                            onClick={handleReset}
                            disabled={!dirty || isSubmitting}>Reset</Button>
                          <Button type="submit" disabled={isSubmitting}>Add</Button>
                          <DisplayFormikState {...props} />
                        </Form>)}
                  </Formik>
                </Tab>
                <Tab eventKey="complete" title="Complete Bet">
                  <Formik
                    initialValues={{ resulttext: '', winner: '' }}
                    onSubmit={async values => {
                      completeBet({
                        id: this.state.id,
                        resulttext: values.resulttext,
                        winner: values.winner,
                        tdbmember: this.state.tdbmember
                      }).then((result) => {
                        this.updateBet();
                        values.resulttext = '';
                      }).catch((e) => {
                        console.log("post complete bet failed");
                      });
                    }}
                    validationSchema={Yup.object().shape({
                      resulttext: Yup.string()
                        .min(10, 'Must be 10 or more characters')
                        .required('Required'),
                      winner: Yup.string()
                        .required('Required')
                    })}
                  >
                    {({ values,
                      handleChange,
                      handleBlur,
                      handleReset,
                      dirty,
                      props,
                      touched,
                      errors,
                      setFieldValue,
                      isSubmitting }) => (
                        <Form>
                          <label htmlFor="winner" style={{ display: "block" }}>
                            Winner</label>
                          Pro
                          <input
                            type="radio"
                            name="winner"
                            value="pro"
                            checked={values.winner === "pro"}
                            onChange={() => setFieldValue("winner", "pro")} />
                          Con
                          <input
                            type="radio"
                            name="winner"
                            value="con"
                            checked={values.winner === "con"}
                            onChange={() => setFieldValue("winner", "con")} />
                          <ErrorMessage component="div" name="winner" className="input-feedback" />
                          <label htmlFor="resulttext" style={{ display: "block" }}>
                            Result</label>
                          <Field
                            name="resulttext"
                            placeholder="Enter the bet result "
                            type="text"
                            value={values.resulttext}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.resulttext && touched.resulttext
                                ? "text-input error"
                                : "text-input"
                            }
                          />
                          <ErrorMessage component="div" name="resulttext" className="input-feedback" />
                          <br />
                          <Button
                            type="button"
                            className="outline"
                            onClick={handleReset}
                            disabled={!dirty || isSubmitting}>Reset</Button>
                          <Button type="submit" disabled={isSubmitting}>Complete</Button>
                        </Form>)}
                  </Formik>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default BetUpdate;
//export default withAuthenticator(Bet, { includeGreetings: true });