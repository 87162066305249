import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .foot {
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
    }
`;

export const Footer = () => (
    <Styles> 
        <Card className="foot">
            <Card.Footer>
                One of you lawyers needs to come up with words for down here, please
            </Card.Footer>
        </Card>
    </Styles>
)