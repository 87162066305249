import React, { Component, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../App.css';
import "../assets/css/helper.css";
//import Amplify, { AUTH } from 'aws-amplify';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Card } from 'react-bootstrap';

// Form Items
import { getMember, updateMember } from '../api/MemberApi';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { DisplayFormikState } from "./helper";
import * as Yup from "yup";

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome, MdPeople, MdPerson } from 'react-icons/md'
import { Link } from 'react-router-dom'

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Members: MdPeople,
    'Update Member': MdPerson
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/members', label: 'Members' },
    { to: '/memberupdate', label: 'Update Member' }
  ]
}

class MemberUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state[0].id,
      member: []
    };
  }

  componentDidMount() {
    this.goGetMember();
  }

  goGetMember() {
    getMember(this.state.id)
      .then((data) => {
        this.setState({
          member: data
        })
      })
      .catch((e) => {
        console.log("getMember failed");
      });
  }

  render(props) {
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>{this.state.member.id}</Card.Title>
                  <Card.Text>
                    First: {this.state.member.firstname}<br />
                    Last: {this.state.member.lastname}<br />
                    Email: {this.state.member.email}<br />
                    Phone: {this.state.member.phonenumber}<br />
                    Receive All SMS: {JSON.stringify(this.state.member.smsreceiveall)} <br />
                    Receive Member SMS: {JSON.stringify(this.state.member.smsreceivemember)} <br />
                    Group: {this.state.member.group}<br />
                    Origin: {this.state.member.origin}<br />
                    Created On: {this.state.member.createdate}<br />
                    ID: {this.state.member.id}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <h2>Update Member</h2>
              <Formik
                enableReinitialize
                initialValues={this.state.member}
                onSubmit={async values => {
                  await new Promise(resolve => setTimeout(resolve, 500));
                  updateMember({
                    id: this.state.id,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    smsreceiveall: values.smsreceiveall,
                    smsreceivemember: values.smsreceivemember
                  }).then((result) => {
                    this.goGetMember();
                  }).catch((e) => {
                    console.log("post Member failed");
                  });
                }}
                validationSchema={Yup.object().shape({
                  firstname: Yup.string()
                    .max(15, 'Must be 15 characters or less')
                    .min(1, 'Must be 1 or more characters')
                    .required('Required'),
                  lastname: Yup.string()
                    .max(20, 'Must be 20 characters or less')
                    .required('Required')
                })}
              >
                {({ values,
                  handleChange,
                  handleBlur,
                  handleReset,
                  dirty,
                  props,
                  touched,
                  errors,
                  isSubmitting }) => (
                    <Form>
                      <label htmlFor="firstname" style={{ display: "block" }}>
                        First Name</label>
                      <Field
                        name="firstname"
                        placeholder="Enter your firstname"
                        type="text"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.firstname && touched.firstname
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      <ErrorMessage component="div" name="firstname" className="input-feedback" />
                      <label htmlFor="lastname" style={{ display: "block" }}>
                        Last Name</label>
                      <Field
                        name="lastname"
                        placeholder="Enter your lastname"
                        type="text"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.lastname && touched.lastname
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      <ErrorMessage component="div" name="lastname" className="input-feedback" />

                      <div className="form-group form-check">
                        <Field type="checkbox" name="smsreceiveall" className={'form-check-input ' + (errors.smsreceiveall && touched.smsreceiveall ? ' is-invalid' : '')} />
                        <label htmlFor="smsreceiveall" className="form-check-label">Receive all notifications</label>
                        <ErrorMessage name="smsreceiveall" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group form-check">
                        <Field type="checkbox" name="smsreceivemember" className={'form-check-input ' + (errors.smsreceivemember && touched.smsreceivemember ? ' is-invalid' : '')} />
                        <label htmlFor="smsreceivemember" className="form-check-label">Receive just my notifications</label>
                        <ErrorMessage name="smsreceivemember" component="div" className="invalid-feedback" />
                      </div>

                      <br />
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}>Reset</Button>
                      <Button type="submit" disabled={isSubmitting}>Submit</Button>
                      <DisplayFormikState {...props} />
                    </Form>)}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default MemberUpdate;
//export default withAuthenticator(Bet, { includeGreetings: true });