import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../App.css';
import "../assets/css/helper.css";
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

import { getCategories, postCategory, removeCategory } from '../api/CategoryApi';
import { Button, Table, Container, Row, Col } from 'react-bootstrap';
import { DisplayFormikState } from "./helper";
import * as Yup from "yup";

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome, MdGroupWork } from 'react-icons/md'
import { Link } from 'react-router-dom'

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Categories: MdGroupWork
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/categories', label: 'Categories' }
  ]
}

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      tdbmember: ''
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    this.updateCategory();
    this.updateMemberInfo();
  }

  updateCategory() {
    getCategories()
      .then((data) => {
        this.setState({
          category: data
        })
      })
      .catch((e) => {
        console.log("getCategories failed");
      });
  }

  async updateMemberInfo() {
    Auth.currentUserInfo({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(
      user => {
        this.setState({
          tdbmember: user.username
        })
      })
      .catch(
        err => console.log(err)
      );
  }

  handleDelete(id) {
    console.log("Delete called for item with id: ", id);
    removeCategory(id)
      .then(() => {
        console.log("Category removed");
        this.updateCategory();

      }).catch((e) => {
        console.log("Could not remove category");
      });
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container>
          <Row>
            <Col>
              <h2>Category Admin Screen</h2>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>Short Name</th>
                    <th>Long Name</th>
                    <th>Created By</th>
                    <th>Create Date</th>
                    <th></th>
                  </tr>
                  {this.state.category.map((item, key) =>
                    <tr key={item.id}>
                      <td>{item.shortname}</td>
                      <td>{item.longname}</td>
                      <td>{item.createdby}</td>
                      <td>{item.createdate}</td>
                      <td><Button onClick={() => this.handleDelete(item.id)}>Delete</Button></td>
                    </tr>
                  )}
                </tbody> 
              </Table>
            </Col>
            <Col>
              <h3>Add Category</h3>
              <Formik
                initialValues={{ shortname: '', longname: '' }}
                onSubmit={async values => {
                  await new Promise(resolve => setTimeout(resolve, 500));
                  postCategory({
                    shortname: values.shortname,
                    longname: values.longname,
                    tdbmember: this.state.tdbmember
                  }).then((result) => {
                    this.updateCategory();
                    values.shortname = '';
                    values.longname = '';
                  }).catch((e) => {
                    console.log("post Category failed");
                  });
                  //alert(JSON.stringify(values, null, 2));
                }}
                validationSchema={Yup.object().shape({
                  shortname: Yup.string()
                    .max(5, 'Must be 5 characters or less')
                    .min(2, 'Must be 2 or more characters')
                    .required('Required'),
                  longname: Yup.string()
                    .max(40, 'Must be 40 characters or less')
                    .required('Required')
                })}
              >
                {({ values,
                  handleChange,
                  handleBlur,
                  handleReset,
                  dirty,
                  props,
                  resetForm,
                  touched,
                  errors,
                  isSubmitting }) => (
                    <Form>
                      <label htmlFor="shortname" style={{ display: "block" }}>
                        Short Name</label>
                      <Field
                        name="shortname"
                        placeholder="Enter your shortname"
                        type="text"
                        value={values.shortname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.shortname && touched.shortname
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      <ErrorMessage component="div" name="shortname" className="input-feedback" />
                      <label htmlFor="longname" style={{ display: "block" }}>
                        Long Name</label>
                      <Field
                        name="longname"
                        placeholder="Enter your longname"
                        type="text"
                        value={values.longname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.longname && touched.longname
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      <ErrorMessage component="div" name="longname" className="input-feedback" />
                      <br />
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}>Reset</Button>
                      <Button type="submit" disabled={isSubmitting}>Submit</Button>
                      <DisplayFormikState {...props} />
                    </Form>)}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Category;
