import React, { Component, Fragment } from 'react'
import '../App.css'
import Amplify from 'aws-amplify'
import awsconfig from '../aws-exports'

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome, MdList, MdViewQuilt } from 'react-icons/md'
import { Link } from 'react-router-dom'

// Shared Components
import BetDetail from './betDetail'

import { getBet } from '../api/BetsApi'
import { Container, Row, Col } from 'react-bootstrap'

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Bets: MdList,
    'Review Bet': MdViewQuilt
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/bets', label: 'Bets' },
    { to: '/review', label: 'Review Bet' }
  ]
}

class BetReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state[0].id,
      bet: [],
      wagers: []
    };
  }

  componentDidMount() {
    this.updateBet();
  }

  updateBet() {
    getBet(this.state.id)
      .then((data) => {
        this.setState({
          bet: data,
          wagers: data.wagers
        })
      })
      .catch((e) => {
        console.log("getBet failed");
      });
  }

  render(props) {
    return(
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col>
              <BetDetail bet={this.state.bet} wagers={this.state.wagers} /> 
            </Col>
          </Row>
        </Container>
      </Fragment>

    )
  }
}

export default BetReview;