import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .navbar {
        background-color: #222;
    }
    .navbar-brand, .navbar-nav .nav-link {
        color: #bbb;
        &:hover {
            color: white;
        }
    }
    .nav-item {
        color: #fff;
    }
`;
class NavigationBar extends Component {
  render() {
    return (
      <Styles>
        <Navbar expand="lg">
          <Navbar.Brand href="/">TwoDollarBet</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="Bets">
                <Nav.Link href="/bets">Open Bets</Nav.Link>
                <Nav.Link href="/new">New Bet</Nav.Link>
              </NavDropdown>
              <Nav.Link href="/members">Members</Nav.Link>
              <Nav.Link href="/category">Category</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    )
  }
}

export default NavigationBar; 