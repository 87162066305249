import React from 'react'
import { Card } from 'react-bootstrap'



const BetDetail = ({...props}) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{props.bet.bettext}</Card.Title>
        <Card.Text>
          Status: {props.bet.betstatus}<br />
          Bet Date: {props.bet.betdate}<br />
          Category: {props.bet.category}<br />
          Created Where: {props.bet.origin}<br />
          Created By: {props.bet.createdby}<br />
          Created On: {props.bet.createdate}<br />
          ID: {props.bet.id}<br />
          Wagers<br />
          {props.wagers.map((item, key) =>
            <li key={item.id}>
              {item.pro},
              {item.con},
              {item.createdate}
            </li>
          )}
          {props.bet.betstatus === 'Closed' &&
            props.bet.resulttext
          }
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default BetDetail

/*
          {props.wagers.map((item, key) =>
            <li key={item.id}>
              {item.pro},
            {item.con},
            {item.createdate}
            </li>
          )}
*/