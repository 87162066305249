import React from 'react';
import '../App.css';
import { HomeMenu } from '../components/Home';
import { Footer } from '../components/Footer';

const MainPage = () => {
    return (
        <div className="App">
            <HomeMenu />
            <Footer />
        </div>
    );
};

export default MainPage;