import React from 'react';
import  { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import regImage from '../assets/register.jpg';

const Styles = styled.div`
    .jumbo {
        background: url(${regImage}) no-repeat fixed bottom;
        background-size: cover;
        color: #ccc;
        height: 200px;
        position: relative;
        z-index: -2;
    }
    .overlay {
        background-color: #000;
        opacity: 0.75;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
`;

export const Jumbotron = () => (
    <Styles>
        <Jumbo fluid className="jumbo">
            <div className="overlay"></div>
            <Container>
                <h1>twodollarbet.com</h1>
                <p>...because any more would be too much</p>
            </Container>
        </Jumbo>
    </Styles>
)