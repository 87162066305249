import { API } from 'aws-amplify';

const API_NAME = 'TwoDollarBetApi';
const PATH = '/bets'

export const postBet = async (bet) => {
  console.log("posting this bet: ", bet);
    return await API.post(API_NAME, PATH, {
        body: {
            betdate: bet.betdate,
            category: bet.category,
            origin: 'Website', 
            bettext: bet.bettext,
            betstatus: 'Open',
            createdby: bet.tdbmember
        }
    });
}

export const addWager = async(bet) => {
    console.log("adding wager: ",bet.id);
    console.log("pro: ", bet.pro);
    console.log("con: ", bet.con);
    return await API.post(API_NAME, PATH + '/object/' + bet.id, {
        body: {
            mode: 'addwager',
            pro: bet.pro,
            con: bet.con,
            createdby: bet.tdbmember
        }
    });
}

export const completeBet = async(bet) => {
    return await API.post(API_NAME, PATH + '/object/' + bet.id, {
        body: {
            mode: 'completebet',
            winner: bet.winner,
            resulttext: bet.resulttext,
            closedby: bet.tdbmember
        }
    });
    
}

export const getAllBets = async() => {
    return await API.get(API_NAME, PATH);
}

export const getBet = async(id) => {
    return await API.get(API_NAME, PATH + '/object/' + id);
}

export const removeBet = async(id) => {
    return await API.del(API_NAME, PATH + '/object/' + id);
}