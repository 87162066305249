import React from 'react';
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import styled from 'styled-components';
import betImage from '../assets/bets.jpg';
import memberImage from '../assets/members.jpg';
import categoryImage from '../assets/categories.jpg';

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome } from 'react-icons/md'

const Styles = styled.div`
.card {
    background-size: cover;
    color: #fff;
    height: 150px;
    width: 300px;
    position: relative;
    z-index: -2;
}
.overlay {
    background-color: #000;
    opacity: 0.75;
    position: relative;
    z-index: -1;
}
.tile {
    height: 150px;
    width: 300px;
}
`;

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome
  },
  items: [
    { to: '/', label: 'Home' }
  ]
}

export const HomeMenu = () => (
  <Styles>
    <Breadcrumb separator={<Separator>/</Separator>}>
      {options.items.map(({ to, label }) => {
        const Icon = options.icons[label];
        return (
          <div key={to} className='some-custom-classname'>
            {Icon && <Icon />}
            <Link to={to}>{label}</Link>
          </div>
        )
      })}
    </Breadcrumb>
    <Container fluid>
      <Row>
        <Link to={{ pathname: "/bets" }}>
          <Card>
            <Card.Img className="tile" src={betImage} />
            <Card.ImgOverlay>
              <Card.Title>Bets</Card.Title>
              <Card.Text >Track your wasted time</Card.Text>
            </Card.ImgOverlay>
          </Card>
        </Link>
        <Link to={{ pathname: "/members" }}>
          <Card>
            <Card.Img className="tile" src={memberImage} />
            <Card.ImgOverlay>
              <Card.Title>Members</Card.Title>
              <Card.Text >Find your idiot friends</Card.Text>
            </Card.ImgOverlay>
          </Card>
        </Link>
        <Link to={{ pathname: "/category" }}>
          <Card>
            <Card.Img className="tile" src={categoryImage} />
            <Card.ImgOverlay>
              <Card.Title>Categories</Card.Title>
              <Card.Text >Big Pineapple, Small Pineapple</Card.Text>
            </Card.ImgOverlay>
          </Card>
        </Link>
      </Row>
    </Container>
  </Styles>
)