import { API } from 'aws-amplify';

const API_NAME = 'TwoDollarBetApi';
const PATH = '/category'

export const postCategory = async (category) => {
    return await API.post(API_NAME, PATH, {
        body: {
            shortname: category.shortname,
            longname: category.longname, 
            createdby: category.tdbmember
        }
    });
}

export const getCategories = async() => {
    return await API.get(API_NAME, PATH);
}

export const getCategory = async(id) => {
    return await API.get(API_NAME, PATH + '/object/' + id);
}

export const removeCategory = async(id) => {
    return await API.del(API_NAME, PATH + '/object/' + id);
}