import React, { Component } from 'react';
import './App.css';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import { BrowserRouter as Router, Switch, Route, Redirect /*, BrowserRouter*/ } from 'react-router-dom';

// Sections
//import Breadcrumb from './Breadcrumb';

// Pages
import MainPage from './pages'
import Bet  from './components/bet'
import NewBet from './components/newbet'
import BetUpdate from './components/betUpdate'
import BetReview from './components/betReview'
import Member  from './components/member'
import MemberUpdate  from './components/memberUpdate'
import Category from './components/category'
import NotFoundPage from './pages/404'
import { Layout } from './components/Layout'
import NavigationBar  from './components/NavigationBar'
import { Jumbotron } from './components/Jumbotron'
Amplify.configure(awsconfig);

class App extends Component {
  render() {
    return(
      <React.Fragment>
        <NavigationBar />
        <Jumbotron/>
        <Layout>
          <Router>
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route exact path="/bets" component={Bet} />
              <Route exact path="/new" component={NewBet} />
              <Route exact path="/betupdate" component={BetUpdate} />
              <Route exaxt path="/review" component={BetReview} />
              <Route exact path="/members" component={Member} />
              <Route exact path="/memberupdate" component={MemberUpdate} />
              <Route exact path="/category" component={Category} />
              <Route exact path="/404" component={NotFoundPage} />
              <Redirect to="/404"/>
            </Switch>
          </Router>
        </Layout> 
      </React.Fragment>
    );
  }
}

export default withAuthenticator(App, { includeGreetings: true });

/* 
        <BrowserRouter>
          <Breadcrumb separator={<Separator>/</Separator>}>
            {options.items.map(({ to, label }) => {
              const Icon = options.icons[label];
              return (
                <div key={to} className='some-custom-classname'>
                  {Icon && <Icon />}
                  <Link to={to}>{label}</Link>
                </div>
              )
            })}
          </Breadcrumb>
        </BrowserRouter>
*/