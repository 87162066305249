import React, { Component, Fragment } from 'react'
import '../App.css'
import "../assets/css/helper.css"
//import Amplify, { AUTH } from 'aws-amplify'
import Amplify from 'aws-amplify'
import awsconfig from '../aws-exports'

// Table Items

// Breadcrumbs
import Breadcrumb from '../Breadcrumb';
import { MdHome, MdList, MdEdit, MdDelete, MdNoteAdd, MdViewQuilt } from 'react-icons/md'
import { Link } from 'react-router-dom';

import { getAllBets /*, removeBet */ } from '../api/BetsApi';
import { Table, Container, Row, Col } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)
 
const options = {
  icons: {
    Home: MdHome,
    Bets: MdList
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/bets', label: 'Bets' }
  ]
}
class Bet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bets: [],
      show: ''
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.updateBets();
    if (this.state.show === '') {
      console.log("setting show state");
      this.setState({
        show: 'Open'
      })
    }
  }

  updateBets() {
    console.log (this.state);
    getAllBets()
      .then((data) => {
        this.setState({
          bets: data
        })
      })
      .catch((e) => {
        console.log("getAllBets failed");
      });
  }

  handleToggle() {
    if (this.state.show === "Open") {
      this.setState({
        show: "Closed"
      })
    }
    else if (this.state.show === "Closed") {
      this.setState({
        show: "Open"
      })
    }
  }

  handleDelete(id) {
    console.log("Delete called for item with id: ", id);
    alert("you aren't allowed to delete bets, dingus");
    /* 
    removeBet(id)
      .then(() => {
        console.log("Bet removed");
        this.updateBets();
      }).catch((e) => {
        console.log("Could not remove bet");
      });
    */  
  }

  
  render() {
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>

        <Container fluid>
          <Row>
            <Col>
              <div className="row">
                <div className="col-md-3">
                  <h3 className="text-muted">
                    Bets
                  </h3>
                </div>
                <div className="col-md-3">
                  <BootstrapSwitchButton
                    checked={false}
                    onlabel='Closed'
                    onstyle='danger'
                    offlabel='Open'
                    offstyle='success'
                    width='100'
                    onChange={this.handleToggle}
                  />
               </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                  <h3 className="text-right text-muted">
                    Create New Bet 
                    <Link to={{ pathname: "/new" }}>
                      <MdNoteAdd />
                    </Link>
                  </h3>
                </div>
              </div>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Category</th>
                    <th>Text</th>
                    <th>Wagers</th>
                    <th>Created By</th>
                    <th></th>
                  </tr>
                  {this.state.bets.filter(bet => bet.betstatus === this.state.show)
                    .map((bet, key) =>
                      <tr key={bet.id}>
                        <td>{bet.betdate}</td>
                        <td>{bet.category}</td>
                        <td>
                          {bet.bettext}
                          {bet.betstatus === 'Closed' &&
                            <div>
                              Result: {bet.resulttext}
                              Winner: {bet.winner}
                            </div>
                          }
                        </td>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td>Pro</td>
                                <td>Con</td>
                              </tr>
                              {bet.wagers.map((wager, key) =>
                                <tr key={wager.id}>
                                  <td>{wager.pro}</td>
                                  <td>{wager.con}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                        <td>{bet.createdby}</td>
                        <td>
                          {bet.betstatus === 'Open'
                            ? <Link to={{ pathname: "/betupdate",
                                state: [{
                                  id: bet.id
                                  }]
                                }}>
                                <MdEdit />
                              </Link>
                            : <Link to={{ pathname: "/review",
                                state: [{
                                  id: bet.id
                                }]
                              }}>
                                <MdViewQuilt />
                              </Link>
                          }
                          <Link to='/bets' onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDelete(bet.id) }}>
                            <MdDelete />
                          </Link>
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Bet;
//export default withAuthenticator(Bet, { includeGreetings: true });