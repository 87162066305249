import React, { Component, Fragment } from 'react';
import '../App.css';
//import Amplify, { AUTH } from 'aws-amplify';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

import { getAllMembers } from '../api/MemberApi';
import { Footer } from './Footer';

// Breadcrumbs
import Breadcrumb from '../Breadcrumb'
import { MdHome, MdPeople } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { Button, Table, Container, Row, Col } from 'react-bootstrap';

Amplify.configure(awsconfig);

const Separator = ({ children, ...props }) => (
  <span style={{ color: 'teal' }} {...props}>
    {children}
  </span>
)

const options = {
  icons: {
    Home: MdHome,
    Members: MdPeople
  },
  items: [
    { to: '/', label: 'Home' },
    { to: '/members', label: 'Members' }
  ]
}

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      members: []
    };

    //this.handleInputChange = this.handleInputChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.updateMembers();
  }

  updateMembers() {
    getAllMembers()
      .then((data) => {
        this.setState({
          members: data
        })
      })
      .catch((e) => {
        console.log("getMember failed");
      });
  }

  handleDelete(id) {
    console.log("Delete called for item with id: ", id);
    alert("Hey asshole, don't delete people");
    /*
    removeMember(id)
      .then(() => {
        console.log("Member removed");
        this.updateMembers();

      }).catch((e) => {
        console.log("Could not remove member");
    });
    */

  }

  render() {
    return (
      <Fragment>
        <Breadcrumb separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
            const Icon = options.icons[label];
            return (
              <div key={to} className='some-custom-classname'>
                {Icon && <Icon />}
                <Link to={to}>{label}</Link>
              </div>
            )
          })}
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col>
              <h2>Member Screen</h2>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>id/username</th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>SMS?</th>
                    <th></th>
                  </tr>
                  {this.state.members.map((item, key) =>
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.lastname}</td>
                      <td>{item.firstname}</td>
                      <td>{item.email}</td>
                      <td>{item.phonenumber}</td>
                      <td>{JSON.stringify(item.smsreceiveall)}</td>
                      <td>
                        <Link to={{
                          pathname: "/memberupdate",
                          state: [{
                            id: item.id
                          }]
                        }}>Update</Link>
                        <Button onClick={() => this.handleDelete(item.id)}>Delete</Button></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

export default Member;
//export default withAuthenticator(Member, { includeGreetings: true });